import '@babel/polyfill';
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import VueMoment from 'vue-moment';
import './plugins/vuetify';
import './plugins/vee-validate';
import App from './App.vue';
import router from './router';
import store from '@/store';
import './registerServiceWorker';
import 'vuetify/dist/vuetify.min.css';
import 'leaflet/dist/leaflet.css';
import i18n from './i18n';

import * as moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

Vue.config.productionTip = false;
Vue.filter('fromMpStoKT', (n: number) => {
  return (n * 3600) / 1852;
});
Vue.filter('fromMtoKM', (n: number) => {
  return n / 1000;
});
Vue.filter('fromMtoNM', (n: number) => {
  return n / 1852;
});
Vue.filter('formatNumber', (n: number, decimals: number = 1) => {
  return Math.round(n * Math.pow(10, decimals)) / Math.pow(10, decimals);
});
Vue.use(VueMoment, {
  moment,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
