import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { OverviewState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { dispatchCheckApiError } from '../main/actions';
import { commitSetLatestData } from './mutations';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<OverviewState, State>;

export const actions = {
  async actionGetLatestData(context: MainContext) {
    const loadingNotification = { content: 'Obtendo dados...', showProgress: true };
    const updatedNotification = { content: 'Dados atualizados', color: 'success' };

    commitAddNotification(context, loadingNotification);
    try {
      const response = await api.getAllLatestData(context.rootState.main.token);
      if (response.data) {
        commitSetLatestData(context, response.data);
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, updatedNotification);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateLatestData(context: MainContext, latestData) {
    commitSetLatestData(context, latestData);
  },
};

const { dispatch } = getStoreAccessors<OverviewState, State>('');

export const dispatchGetLatestData = dispatch(actions.actionGetLatestData);
export const dispatchUpdateLatestData = dispatch(actions.actionUpdateLatestData);
