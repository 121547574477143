import Vue from 'vue';
import Vuetify from 'vuetify';

const pt = {
  dataIterator: {
    rowsPerPageText: 'Itens por página:',
    rowsPerPageAll: 'Todos',
    pageText: '{0}-{1} de {2}',
    noResultsText: 'Nenhum dado encontrado',
    nextPage: 'Próxima página',
    prevPage: 'Página anterior'
  },
  dataTable: {
    rowsPerPageText: 'Linhas por página:'
  },
  noDataText: 'Não há dados disponíveis',
  carousel: {
    prev: 'Visual anterior',
    next: 'Próximo visual'
  }
}

Vue.use(Vuetify, {
  theme: {
    primary: '#1e4764',
    accent: '#003347',
    secondary: '#8dbc29',
    success: '#8dbc29',
    warning: '#71a2b9',
    error: '#ef3b1f',
    red: '#f74902',
    orange: '#fca31c',
    green: '#8cd600',
    blue: '#00425c',
    lightblue: '#71a2b9',
  },
  iconfont: 'md',
  lang: {
    locales: { pt },
    current: 'pt'
  }
});
