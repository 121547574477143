import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate } from './interfaces';
import { IStation, IEquipment } from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUser(token: string, userId: number) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getStations(token: string) {
    return axios.get<IStation[]>(`${apiUrl}/api/v1/stations/`, authHeaders(token));
  },
  async getStationsForExporting(token: string) {
    return axios.get<IStation[]>(`${apiUrl}/api/v1/stations/`, {
      params: { active_only: false },
      ...authHeaders(token),
    });
  },
  async getEquipments(token: string) {
    return axios.get<IEquipment[]>(`${apiUrl}/api/v1/equipments/`, authHeaders(token));
  },
  async getEquipment(token: string, equipmentId: number) {
    return axios.get<IEquipment>(`${apiUrl}/api/v1/equipments/${equipmentId}`, authHeaders(token));
  },
  async getEquipmentHealth(token: string, equipmentId: number) {
    return axios.get<any>(`${apiUrl}/api/v1/equipments/${equipmentId}/health`, authHeaders(token));
  },
  async getEquipmentDataByCategory(token: string, equipmentId: number, dataCategory: string) {
    return axios.get<any>(
      `${apiUrl}/api/v1/equipments/${equipmentId}/data/${dataCategory}`,
      authHeaders(token),
    );
  },
  async getAllLatestData(token: string) {
    return axios.get<any>(`${apiUrl}/api/v1/data/all/latest`, {
      params: { get_related: false },
      ...authHeaders(token),
    });
  },
  async switchEquipmentMaintenanceState(token: string, equipmentId: number) {
    return axios.put<any>(
      `${apiUrl}/api/v1/equipments/${equipmentId}/switch_maintenance`,
      {},
      authHeaders(token),
    );
  },
  async switchEquipmentActiveState(token: string, equipmentId: number) {
    return axios.put<any>(
      `${apiUrl}/api/v1/equipments/${equipmentId}/switch_active`,
      {},
      authHeaders(token),
    );
  },
};
