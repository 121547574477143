import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { mainModule } from './main';
import { State } from './state';
import { adminModule } from './admin';
import { stationsModule } from './stations';
import { equipmentsModule } from './equipments';
import { dataModule } from './data';
import { overviewsModule } from './overviews';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    station: stationsModule,
    equipment: equipmentsModule,
    data: dataModule,
    overview: overviewsModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
