import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'recover-password',
          component: () =>
            import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () =>
            import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () =>
                import(/* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'),
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'
                    ),
                },
                {
                  path: 'edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'
                    ),
                },
                {
                  path: 'password',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'
                    ),
                },
              ],
            },
            {
              path: 'stations',
              component: RouterComponent,
              redirect: 'stations/all',
              children: [
                {
                  path: 'all',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-stations" */ './views/main/stations/Stations.vue'
                    ),
                },
              ],
            },
            {
              path: 'overviews',
              component: RouterComponent,
              children: [
                // {
                //   path: 'stations',
                //   component: () => import(
                //     /* webpackChunkName: "main-overviews-stations" */
                //     './views/main/overviews/MonitoringStations.vue'),
                // },
                {
                  path: 'T1',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-overviews-T1" */ './views/main/overviews/T1.vue'
                    ),
                },
                {
                  path: 'T2',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-overviews-T2" */ './views/main/overviews/T2.vue'
                    ),
                },
                {
                  path: 'VTS',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-overviews-VTS" */ './views/main/overviews/VTS.vue'
                    ),
                },
              ],
            },
            {
              path: 'equipments',
              component: RouterComponent,
              redirect: 'equipments/all',
              children: [
                {
                  path: 'all',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-equipments" */ './views/main/equipments/Equipments.vue'
                    ),
                },
                {
                  path: ':id',
                  name: 'main-equipments-show',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-equipment" */ './views/main/equipments/Equipment.vue'
                    ),
                },
              ],
            },
            {
              path: 'export',
              component: () =>
                import(/* webpackChunkName: "main-export" */ './views/main/Export.vue'),
            },
            {
              path: 'export/multi',
              component: () =>
                import(
                  /* webpackChunkName: "main-export-multi" */ './views/main/ExportMulti.vue'
                ),
            },
            {
              path: 'admin',
              component: () =>
                import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'
                    ),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'
                    ),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'
                    ),
                },
                {
                  path: 'equipments',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users" */ './views/main/admin/Equipments.vue'
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*',
      redirect: '/',
    },
  ],
});
