import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { OverviewState } from './state';

export const getters = {
  latestData: (state: OverviewState) => state.latestData,
};

const { read } = getStoreAccessors<OverviewState, State>('');

export const getLatestData = read(getters.latestData);
