import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { EquipmentState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitSetEquipments,
  commitSetEquipment,
  commitSetEquipmentHealthData,
  commitDeleteEquipmentData,
  commitSetEquipmentData,
  commitUnsetLoadedData,
  commitSetLoadedData,
  commitUpdateData,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { AxiosError } from 'axios';

type MainContext = ActionContext<EquipmentState, State>;

export const actions = {
  async actionGetEquipments(context: MainContext) {
    try {
      const response = await api.getEquipments(context.rootState.main.token);
      if (response) {
        commitSetEquipments(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetEquipment(context: MainContext, id: number) {
    try {
      const response = await api.getEquipment(context.rootState.main.token, id);
      if (response.data) {
        commitSetEquipment(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetEquipmentHealthData(context: MainContext, id: number){
    try {
      const response = await api.getEquipmentHealth(context.rootState.main.token, id);
      if (response.status == 200 && response.data) {
        commitSetEquipmentHealthData(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetEquipmentData(context: MainContext, payload: any) {
    const equipment = payload;
    await equipment.categories.forEach(async (c) => {
      const category = c.name.toLowerCase();
      try {
        const response = await api.getEquipmentDataByCategory(
          context.rootState.main.token,
          equipment.id,
          category,
        );
        if (response.data) {
          commitSetEquipmentData(context, {category,  data: response.data[`${category}_data`]});
          dispatchSetLoadedData(context);
        }
      } catch (error) {
        await dispatchCheckApiError(context, error as AxiosError);
      }
    });
  },
  actionDeleteEquipmentData(context: MainContext) {
    commitDeleteEquipmentData(context);
  },
  actionUnsetLoadedData(context: MainContext) {
    commitUnsetLoadedData(context);
  },
  actionSetLoadedData(context: MainContext) {
    commitSetLoadedData(context);
  },
  actionUpdateData(context: MainContext, payload: any) {
    commitUpdateData(context, payload);
  },
  async actionSwitchMaintenanceState(context: MainContext, payload: number) {
    try {
      const response = await api.switchEquipmentMaintenanceState(context.rootState.main.token, payload);
      if (response) {
        commitSetEquipment(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionSwitchActiveState(context: MainContext, payload: number) {
    try {
      const response = await api.switchEquipmentActiveState(context.rootState.main.token, payload);
      if (response) {
        commitSetEquipment(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<EquipmentState, State>('');

export const dispatchGetEquipments = dispatch(actions.actionGetEquipments);
export const dispatchGetEquipment = dispatch(actions.actionGetEquipment);
export const dispatchGetEquipmentHealthData = dispatch(actions.actionGetEquipmentHealthData);
export const dispatchGetEquipmentData = dispatch(actions.actionGetEquipmentData);
export const dispatchDeleteEquipmentData = dispatch(actions.actionDeleteEquipmentData);
export const dispatchUnsetLoadedData = dispatch(actions.actionUnsetLoadedData);
export const dispatchSetLoadedData = dispatch(actions.actionSetLoadedData);
export const dispatchUpdateData = dispatch(actions.actionUpdateData);
export const dispatchSwitchMaintenanceState = dispatch(actions.actionSwitchMaintenanceState);
export const dispatchSwitchActiveState = dispatch(actions.actionSwitchActiveState);