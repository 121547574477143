import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { StationState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetStations } from './mutations';
import { dispatchCheckApiError } from '../main/actions';

type MainContext = ActionContext<StationState, State>;

export const actions = {
  async actionGetStations(context: MainContext) {
    try {
      const response = await api.getStations(context.rootState.main.token);
      if (response) {
        commitSetStations(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetStationsForExporting(context: MainContext) {
    try {
      const response = await api.getStationsForExporting(context.rootState.main.token);
      if (response) {
        commitSetStations(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<StationState, State>('');

export const dispatchGetStations = dispatch(actions.actionGetStations);
export const dispatchGetStationsForExporting = dispatch(actions.actionGetStationsForExporting);
