import { IEquipment } from '@/interfaces';
import { EquipmentState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

const equipmentType = {
  EM: 'Estação meteorológica',
  ADP: 'ADCP',
  V: 'Visibilímetro',
  M: 'Marégrafo',
  SSB: 'Ondógrafo',
  P: 'Pluviômetro',
  A: 'Anemômetro',
  B: 'Barômetro',
  T: 'Termômetro',
  H: 'Higrômetro',
  C: 'Correntômetro',
  O: 'Ondógrafo',
  S: 'Salinômetro',
};

const dateSort = (a, b) => new Date(a.datetime) < new Date(b.datetime);

export const mutations = {
  setEquipments(state: EquipmentState, payload: IEquipment[]) {
    state.equipments = payload;
    state.equipments.map(
      (el, index, array) =>
        (array[index].type = equipmentType[el.identifier.replace(/[0-9]/g, '')]),
    );
  },
  setEquipment(state: EquipmentState, payload: IEquipment) {
    state.equipment = payload;
    state.equipment.type = equipmentType[payload.identifier.replace(/[0-9]/g, '')];
  },
  setEquipmentHealthData(state: EquipmentState, payload: any) {
    state.health = { current: null, wave: null };
    state.health.current = payload.current.sort(dateSort);
    state.health.wave = payload.wave.sort(dateSort);
  },
  setEquipmentData(state: EquipmentState, payload: any) {
    state[`${payload.category}_data`] = payload.data;
  },
  updateData(state: EquipmentState, payload) {
    for (const key of Object.keys(payload.data[payload.equipment_id])) {
      const stateCopy = Object.assign({}, state);
      const value: any = payload.data[payload.equipment_id][key];
      const last = stateCopy[key].pop();
      stateCopy[key].push(last);
      if (last.measured_at !== value.measured_at) {
        stateCopy[key].shift();
        stateCopy[key].push(value);
      }
      state[key] = stateCopy[key];
    }
  },
  deleteEquipmentData(state: EquipmentState) {
    state.wind_data = [];
    state.wave_data = [];
    state.weather_data = [];
    state.tide_data = [];
    state.current_data = [];
    state.health = null;
  },
  unsetLoadedData(state: EquipmentState) {
    state.loaded = {
      wind: false,
      weather: false,
      wave: false,
      tide: false,
      current: false,
    };
    state.params = {
      wind_speed: false,
      gust_speed: false,
      wind_direction: false,
      current_speed: false,
      current_direction: false,
      water_level: false,
      significant_wave_height: false,
      peak_wave_period: false,
      peak_wave_direction: false,
      visibility: false,
      air_pressure: false,
      air_temperature: false,
      water_temperature: false,
      precipitation: false,
    };
  },
  setLoadedData(state: EquipmentState) {
    if (state.wind_data.length > 0) {
      state.loaded.wind = true;
      if (state.wind_data[0].wind_speed != null) {
        state.params.wind_speed = true;
      }
      if (state.wind_data[0].gust_speed != null) {
        state.params.gust_speed = true;
      }
      if (state.wind_data[0].wind_direction != null) {
        state.params.wind_direction = true;
      }
    }
    if (state.weather_data.length > 0) {
      state.loaded.weather = true;
      if (state.weather_data[0].visibility != null) {
        state.params.visibility = true;
      }
      if (state.weather_data[0].air_pressure != null) {
        state.params.air_pressure = true;
      }
      if (state.weather_data[0].air_temperature != null) {
        state.params.air_temperature = true;
      }
      if (state.weather_data[0].water_temperature != null) {
        state.params.water_temperature = true;
      }
      if (state.weather_data[0].precipitation != null) {
        state.params.precipitation = true;
      }
    }
    if (state.wave_data.length > 0) {
      state.loaded.wave = true;
      if (state.wave_data[0].significant_wave_height != null) {
        state.params.significant_wave_height = true;
      }
      if (state.wave_data[0].peak_wave_period != null) {
        state.params.peak_wave_period = true;
      }
      if (state.wave_data[0].peak_wave_direction != null) {
        state.params.peak_wave_direction = true;
      }
    }
    if (state.tide_data.length > 0) {
      state.loaded.tide = true;
      if (state.tide_data[0].water_level != null) {
        state.params.water_level = true;
      }
    }
    if (state.current_data.length > 0) {
      state.loaded.current = true;
      if (state.current_data[0].current_speed != null) {
        state.params.current_speed = true;
      }
      if (state.current_data[0].current_direction != null) {
        state.params.current_direction = true;
      }
    }
  },
};

const { commit } = getStoreAccessors<EquipmentState, State>('');

export const commitSetEquipments = commit(mutations.setEquipments);
export const commitSetEquipment = commit(mutations.setEquipment);
export const commitSetEquipmentHealthData = commit(mutations.setEquipmentHealthData);
export const commitSetEquipmentData = commit(mutations.setEquipmentData);
export const commitDeleteEquipmentData = commit(mutations.deleteEquipmentData);
export const commitUnsetLoadedData = commit(mutations.unsetLoadedData);
export const commitSetLoadedData = commit(mutations.setLoadedData);
export const commitUpdateData = commit(mutations.updateData);
