import { IStation } from '@/interfaces';
import { StationState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

const equipmentType = {
  EM: 'Estação meteorológica',
  ADP: 'ADCP',
  V: 'Visibilímetro',
  M: 'Marégrafo',
  SSB: 'Ondógrafo',
  P: 'Pluviômetro',
  A: 'Anemômetro',
  B: 'Barômetro',
  T: 'Termômetro',
  H: 'Higrômetro',
  C: 'Correntômetro',
  O: 'Ondógrafo',
  S: 'Salinômetro',
};

export const mutations = {
  setStations(state: StationState, payload: IStation[]) {
    state.stations = payload;
    state.stations.map( (el0, index0, array0) => array0[index0].equipments.map((el, index, array) => array[index].type = equipmentType[el.identifier.replace(/[0-9]/g, '')]))
  },
};

const { commit } = getStoreAccessors<StationState, State>('');

export const commitSetStations = commit(mutations.setStations);
