import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { EquipmentState } from './state';

const defaultState: EquipmentState = {
  health: null,
  equipments: [],
  equipment: null,
  wind_data: [],
  wave_data: [],
  weather_data: [],
  tide_data: [],
  current_data: [],
  loaded: {
    wind: false,
    wave: false,
    weather: false,
    tide: false,
    current: false,
  },
  params: {
    wind_speed: false,
    gust_speed: false,
    wind_direction: false,
    current_speed: false,
    current_direction: false,
    water_level: false,
    significant_wave_height: false,
    peak_wave_period: false,
    peak_wave_direction: false,
    visibility: false,
    air_pressure: false,
    air_temperature: false,
    water_temperature: false,
    precipitation: false,
  },
};

export const equipmentsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
