import { EquipmentState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  equipment_health: (state: EquipmentState) => state.health,
  equipments: (state: EquipmentState) => state.equipments,
  equipment: (state: EquipmentState) => state.equipment,
  wind_data: (state: EquipmentState) => state.wind_data,
  weather_data: (state: EquipmentState) => state.weather_data,
  wave_data: (state: EquipmentState) => state.wave_data,
  tide_data: (state: EquipmentState) => state.tide_data,
  current_data: (state: EquipmentState) => state.current_data,
  latest_data: (state: EquipmentState) => {
    const latest: any = {};
    if (state.loaded.wind) {
      latest.wind = {
        time: state.wind_data[state.wind_data.length - 1].measured_at,
        created_at: state.wind_data[state.wind_data.length - 1].created_at,
        wind_speed: state.wind_data[state.wind_data.length - 1].wind_speed,
        gust_speed: state.wind_data[state.wind_data.length - 1].gust_speed,
        wind_direction: state.wind_data[state.wind_data.length - 1].wind_direction,
      };
    }
    if (state.loaded.wave) {
      latest.wave = {
        time: state.wave_data[state.wave_data.length - 1].measured_at,
        created_at: state.wave_data[state.wave_data.length - 1].created_at,
        significant_wave_height:
          state.wave_data[state.wave_data.length - 1].significant_wave_height,
        peak_wave_period: state.wave_data[state.wave_data.length - 1].peak_wave_period,
        peak_wave_direction: state.wave_data[state.wave_data.length - 1].peak_wave_direction,
      };
    }
    if (state.loaded.weather) {
      latest.weather = {
        time: state.weather_data[state.weather_data.length - 1].measured_at,
        created_at: state.weather_data[state.weather_data.length - 1].created_at,
        visibility: state.weather_data[state.weather_data.length - 1].visibility,
        precipitation: state.weather_data[state.weather_data.length - 1].precipitation,
        water_temperature: state.weather_data[state.weather_data.length - 1].water_temperature,
      };
    }
    if (state.loaded.tide) {
      latest.tide = {
        time: state.tide_data[state.tide_data.length - 1].measured_at,
        created_at: state.tide_data[state.tide_data.length - 1].created_at,
        water_level: state.tide_data[state.tide_data.length - 1].water_level,
      };
    }
    if (state.loaded.current) {
      latest.current = {
        time: state.current_data[state.current_data.length - 1].measured_at,
        created_at: state.current_data[state.current_data.length - 1].created_at,
        current_speed: state.current_data[state.current_data.length - 1].current_speed,
        current_direction: state.current_data[state.current_data.length - 1].current_direction,
        surface_current_speed: state.current_data[state.current_data.length - 1].surface_current_speed,
        surface_current_direction: state.current_data[state.current_data.length - 1].surface_current_direction,
        middle_current_speed: state.current_data[state.current_data.length - 1].middle_current_speed,
        middle_current_direction: state.current_data[state.current_data.length - 1].middle_current_direction,
        bottom_current_speed: state.current_data[state.current_data.length - 1].bottom_current_speed,
        bottom_current_direction: state.current_data[state.current_data.length - 1].bottom_current_direction,
      };
    }

    return latest;
  },
  chart_data: (state: EquipmentState) => {
    const cd: any = {
      wind: {},
      weather: {},
      wave: {},
      current: {},
      tide: {},
    };
    if (state.loaded.wind) {
      cd.wind.wind_speed = state.wind_data.map((e) => {
        return [new Date(e.measured_at).valueOf(), e.wind_speed * 1.94384];
      });
      cd.wind.gust_speed = state.wind_data.map((e) => {
        return [new Date(e.measured_at).valueOf(), e.gust_speed * 1.94384];
      });
      cd.wind.wind_direction = state.wind_data.map((e) => {
        return [new Date(e.measured_at).valueOf(), 1, e.wind_direction];
      });
    }
    if (state.loaded.current) {
      cd.current.current_speed = state.current_data.map((e) => {
        return [new Date(e.measured_at).valueOf(), e.current_speed * 1.94384];
      });
      cd.current.current_direction = state.current_data.map((e) => {
        return [new Date(e.measured_at).valueOf(), 1, (e.current_direction + 180) % 360];
      });
    }
    if (state.loaded.tide) {
      cd.tide.water_level = state.tide_data.map((e) => {
        return [new Date(e.measured_at).valueOf(), e.water_level];
      });
    }
    if (state.loaded.weather) {
      if (state.params.visibility) {
        cd.weather.visibility = state.weather_data.map((e) => {
          return [new Date(e.measured_at).valueOf(), e.visibility / 1000];
        });
      }
      if (state.params.precipitation) {
        cd.weather.precipitation = state.weather_data.map((e) => {
          return [new Date(e.measured_at).valueOf(), e.precipitation];
        });
      }
      if (state.params.air_pressure) {
        cd.weather.air_pressure = state.weather_data.map((e) => {
          return [new Date(e.measured_at).valueOf(), e.air_pressure];
        });
      }
      if (state.params.air_temperature) {
        cd.weather.air_temperature = state.weather_data.map((e) => {
          return [new Date(e.measured_at).valueOf(), e.air_temperature];
        });
      }
      if (state.params.water_temperature) {
        cd.weather.water_temperature = state.weather_data.map((e) => {
          return [new Date(e.measured_at).valueOf(), e.water_temperature];
        });
      }
    }
    if (state.loaded.wave) {
      if (state.params.significant_wave_height) {
        cd.wave.significant_wave_height = state.wave_data.map((e) => {
          return [new Date(e.measured_at).valueOf(), e.significant_wave_height];
        });
      }
      if (state.params.peak_wave_period) {
        cd.wave.peak_wave_period = state.wave_data.map((e) => {
          return [new Date(e.measured_at).valueOf(), e.peak_wave_period];
        });
      }
      if (state.params.peak_wave_direction) {
        cd.wave.peak_wave_direction = state.wave_data.map((e) => {
          return [new Date(e.measured_at).valueOf(), 1, e.peak_wave_direction];
        });
      }
    }
    return cd;
  },
  loaded_data: (state: EquipmentState) => state.loaded,
  available_params: (state: EquipmentState) => state.params,
};

const { read } = getStoreAccessors<EquipmentState, State>('');

export const readEquipments = read(getters.equipments);
export const readEquipment = read(getters.equipment);
export const readEquipmentHealth = read(getters.equipment_health);
export const readWindData = read(getters.wind_data);
export const readWeatherData = read(getters.weather_data);
export const readWaveData = read(getters.wave_data);
export const readTideData = read(getters.tide_data);
export const readCurrentData = read(getters.current_data);
export const readChartData = read(getters.chart_data);
export const readLoadedData = read(getters.loaded_data);
export const readAvailableParams = read(getters.available_params);
export const readLatestData = read(getters.latest_data);
