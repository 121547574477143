import { DataState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
  setAllLatestData(state: DataState, payload) {
    state.data = payload;
  },
};

const { commit } = getStoreAccessors<DataState, State>('');

export const commitSetAllLatestData = commit(mutations.setAllLatestData);
