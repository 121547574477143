import { DataState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  allLatestData: (state: DataState) => state.data,
};

const { read } = getStoreAccessors<DataState, State>('');

export const readAllLatestData = read(getters.allLatestData);
