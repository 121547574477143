import { OverviewState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { ILatestData } from '@/interfaces';

export const mutations = {
  setLatestData(state: OverviewState, payload: ILatestData[]) {
    state.latestData = payload;
  },
};

const { commit } = getStoreAccessors<OverviewState, State>('');

export const commitSetLatestData = commit(mutations.setLatestData);
