import { StationState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  stations: (state: StationState) => state.stations,
};

const { read } = getStoreAccessors<StationState, State>('');

export const readStations = read(getters.stations);
