import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { OverviewState } from './state';

const defaultState: OverviewState = {
  latestData: [],
};

export const overviewsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
